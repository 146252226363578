import React from "react";

const HomeBanner = () => {
  return (
    <>
      <section id="hero-11" class="bg--fixed hero-section">
        <div class="container">
          <div class="row">
            <div class="col-lg-7">
              <div class="text-lg-start text-center">
                <h5 className="text-white ">Create Your Image</h5>

                <h2 className="text-white display-1 fw-bold text-uppercase my-3">Feel Hot, Look Hot.</h2>

                <p className="text-white">
                  Aliqum mullam blandit tempor justo a sapien gravida ipsum
                  porta justo velna auctor congue
                </p>

                <a href="#waitlist" class="btn btn--tra-black hover--black">
                  View Salon Menu
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default HomeBanner;
